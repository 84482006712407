html {
    /* height: 100%; */
}

body {
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: auto;
    direction: rtl;
    /* background: linear-gradient( #dcfce7, rgba(255,255,255,0)); */
}

img {
    max-width: 100%;
}